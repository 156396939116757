import React from "react";

function Footer() {
  return (
    <div className="title">
      <p className="lead" style={{ color: "whitesmoke" }}>
        Copyright &copy; 2022 Karim William
      </p>
    </div>
  );
}

export default Footer;
